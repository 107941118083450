<template>
  <div>
    <button
      class="btn btn-success"
      style="margin-bottom: 20px;"
      @click="generateReport()"
    >
      Gerar relatório
    </button>
    <div class="row">
      <div class="col-md-4">
        <h3>
          Assistindo no dia
          <b-badge
            variant="success"
            pill
            class="badge-round"
          >
            {{ liveInfo.countWatchDuring }}
          </b-badge>
        </h3>
        <b-list-group v-if="liveInfo.watchDuring.length > 0">
          <b-list-group-item
            v-for="(user, index) in liveInfo.users"
            :key="index"
          >
            {{ user.name }}
          </b-list-group-item>
        </b-list-group>
      </div>

      <div class="col-md-4">
        <h3>
          Total assistido
          <b-badge
            variant="success"
            pill
            class="badge-round"
          >
            {{ liveInfo.countUsers }}
          </b-badge>
        </h3>
        <b-list-group v-if="liveInfo.users.length > 0">
          <b-list-group-item
            v-for="(user, index) in liveInfo.users"
            :key="index"
          >
            {{ user.name }}
          </b-list-group-item>
        </b-list-group>
      </div>
      <VueHtml2pdf
        ref="html2Pdf"
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="relatorio-de-live"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="800px"
      >
        <section
          slot="pdf-content"
          style="padding: 50px; text-align: justify; font-family: Arial;"
        >
          <h1>Relatório de live</h1>
          <span style="font-size: 14pt;">Usuários que assistiram no dia da apresentação - {{ liveInfo.countUsers }}) usuários:</span>
          <p style="font-size: 12pt;">
            <span
              v-for="(user, index) in liveInfo.users"
              :key="index"
            >
              {{ user.name }}; &nbsp;
            </span>
          </p>
          <span style="font-size: 14pt;">Usuários que acessaram a a repsentação (GERAL) - {{ liveInfo.countUsers }} usuários:</span>
          <p style="font-size: 12pt;">
            <span
              v-for="(user, index) in liveInfo.users"
              :key="`${index}-users`"
            >
              {{ user.name }}; &nbsp;
            </span>
          </p>
        </section>
      </VueHtml2pdf>
    </div>
  </div>
</template>

<script>
import LivesServices from './LivesServices'
import VueHtml2pdf from 'vue-html2pdf'

export default {
  components: {
    VueHtml2pdf,
  },
  data() {
    return {
      date: '',
      liveInfo: {
        users: [],
        watchDuring: [],
        countWatchDuring: 0,
        countUsers: 0,
      },
    }
  },
  mounted() {
    LivesServices().liveMonitoringInfo({
      live_id: this.$route.params.live_id
    }).then(api => {
      console.log(api)
      this.liveInfo = api.data.response.monitoringData
    })
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>
